import React from "react";
import { UserRoutes } from "./Routes/UserRoutes";
import './Styles/Normalize.css'

function App() {
  return(
    <UserRoutes />
  )
}

export default App;
